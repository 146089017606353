import { Flex, Grid, GridItem, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import ChartRealizedPnl from 'components/AccountPortfolio/Charts/ChartRealizedPnl';
import ChartsTabs from 'components/AccountPortfolio/Charts/ChartTabs';
import PortfolioHeader from 'components/AccountPortfolio/Header/PortfolioHeader';
import Overview from 'components/AccountPortfolio/Overview';
import PortfolioTables from 'components/AccountPortfolio/Tables/PortfolioTables';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiList } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { SET_SELECTED_MARKET } from 'store/market/slice';
import { DDXIcon } from 'theme/icons';

import Layout from '../components/Layout';

export default function AccountPortfolio() {
    const isDesktopView = useScreenSize();
    const { t } = useTranslation();
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const dispatch = useDispatch();

    dispatch(SET_SELECTED_MARKET({ symbol: 'ETHP' }));

    function CardGridItem({
        gridArea,
        children,
        ...props
    }: {
        gridArea: string;
        children: ReactNode;
        [x: string]: any;
    }) {
        return (
            <GridItem
                gridArea={gridArea}
                borderRadius="base"
                bgColor="card.100"
                p="0rem"
                sx={{
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                    scrollbarWidth: 'none',
                }}
                {...props}
            >
                {children}
            </GridItem>
        );
    }

    function MobileTab({ icon, text, index }: { icon: any; text: string; index: number }) {
        return (
            <Tab
                _focus={{ boxShadow: 'none' }}
                _selected={{
                    color: 'white',
                    border: 'solid white',
                    borderWidth: '0 0 2px',
                    margin: '0 0 -2px',
                }}
                onClick={() => {
                    setActiveTabIndex(index);
                }}
            >
                <Flex direction="column" justify="center" align="center">
                    <Icon as={icon} />
                    <Text fontWeight="700" fontSize="0.75rem">
                        {text}
                    </Text>
                </Flex>
            </Tab>
        );
    }

    function MobileTabPanel({ children, ...props }: { children: any; [x: string]: any }) {
        return (
            <TabPanel
                borderRadius="base"
                bgColor="card.100"
                m="0.25rem"
                overflowY="scroll"
                p="0rem"
                h="calc(100vh - 8.5rem)"
                sx={{
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                    scrollbarWidth: 'none',
                }}
                {...props}
            >
                {children}
            </TabPanel>
        );
    }

    return (
        <Layout>
            {isDesktopView ? (
                <Grid
                    w="100%"
                    gridGap={1}
                    p="0.25rem"
                    gridTemplateColumns="140px 1fr 1fr 140px"
                    gridTemplateRows="50px 3fr 3fr 3fr 3fr"
                    gridTemplateAreas="
                            'EmptyLeft PortfolioHeader PortfolioHeader EmptyRight'
                            'EmptyLeft Overview Charts EmptyRight'
                            'EmptyLeft Overview Charts EmptyRight'
                            'EmptyLeft Tables Tables EmptyRight'
                            'EmptyLeft Tables Tables EmptyRight'"
                    h="100%"
                >
                    <CardGridItem gridArea="PortfolioHeader" p="0.5rem">
                        <ErrorBoundary>
                            <PortfolioHeader />
                        </ErrorBoundary>
                    </CardGridItem>
                    <CardGridItem gridArea="Overview" p="1rem">
                        <ErrorBoundary>
                            <Overview />
                        </ErrorBoundary>
                    </CardGridItem>
                    <CardGridItem gridArea="Charts">
                        <ErrorBoundary>
                            <ChartsTabs />
                        </ErrorBoundary>
                    </CardGridItem>
                    <CardGridItem gridArea="Tables">
                        <PortfolioTables />
                    </CardGridItem>
                </Grid>
            ) : (
                <Tabs isFitted defaultIndex={activeTabIndex} colorScheme="white">
                    <TabPanels>
                        <MobileTabPanel p="1rem">
                            <Flex mt="0.5rem" mb="0.5rem" direction="column" gap="0.5rem" h="100%" w="100%">
                                <PortfolioHeader />
                                <Overview />
                                <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Realized Pnl</Heading>
                                <ChartRealizedPnl />
                            </Flex>
                        </MobileTabPanel>
                        <MobileTabPanel>
                            <PortfolioTables />
                        </MobileTabPanel>
                    </TabPanels>
                    <TabList
                        bottom={0}
                        position="relative"
                        w="100%"
                        bgColor="card.100"
                        borderTop="1px solid rgba(255,255,255,.06)"
                        color="text.100"
                    >
                        <MobileTab icon={DDXIcon} text={'Overview'} index={0} />
                        <MobileTab icon={FiList} text={'History'} index={2} />
                    </TabList>
                </Tabs>
            )}
        </Layout>
    );
}
