import { BigNumber } from '@0x/utils';
import { OrderRejectReason } from '@derivadex/types';

export function getRejectionReasonMessageId(
    isFullyRejected: boolean,
    amount: BigNumber,
    reason: OrderRejectReason,
): string {
    let messageId = '';
    switch (reason) {
        case OrderRejectReason.MarketOrderNotFullyFilled:
            messageId = 'orderRejectMarketNotFilled';
            break;
        case OrderRejectReason.SelfMatch:
            if (isFullyRejected) {
                messageId = 'orderRejectSelfMatchFully';
            } else {
                messageId = 'orderRejectSelfMatchPartially';
            }
            break;
        case OrderRejectReason.Solvency:
            if (isFullyRejected) {
                messageId = 'orderRejectSolvencyFully';
            } else {
                messageId = 'orderRejectSolvencyPartially';
            }
            break;
        default:
            break;
    }
    return messageId;
}
