export function getExplorerLink(defaultExplorerHost: string): string {
    const host = window.location.host;
    const deployment = getDeploymentEnvironmentName(host, defaultExplorerHost);
    return `https://${deployment === 'mainnet' ? '' : `${deployment}-`}explorer.derivadex.io`;
}

export function getDeploymentEnvironmentName(host: string, defaultExplorerHost: string): string {
    if (host === 'exchange.derivadex.com') {
        return 'mainnet';
    } else if (host.startsWith('localhost:')) {
        return defaultExplorerHost;
    }
    const match = host.match(/^(.*?)\./);
    return match ? match[1] : '';
}
